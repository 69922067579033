import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, NavItem } from "react-bootstrap"
import ScrollAnimation from "react-animate-on-scroll"
import Image1 from "../../../images/img6.jpg"
import Slider from "react-slick"
import "./RelatedTeam.scss"
import ReactMarkdown from "react-markdown"
import GetURL from "@Components/common/site/get-url"
import { useWindowSize } from "../../../hooks/window-size"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  containerVariants,
  delayItemsFromBottom,
  fadeInFromRight,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import PlayVideo from "../../PlayVideo/PlayVideo"
import { InView } from "react-intersection-observer"
import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { NEWS_PAGE_URL } from "@Components/common/site/constants"
import NoImage from "../../../images/no-image.png"
import { useStaticQuery, graphql } from "gatsby"
import { Team_Page_Url } from "@Components/common/site/constants"
import Modal from "react-bootstrap/Modal"
import TeamForm from "@Components/forms/teamcontact"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"
const RelatedTeam = props => {
  const [show, setShow] = useState(false)
  const [index, setIndex] = useState(null)
  const handleClose = () => setShow(false)
  const handleShow = event => {
    setIndex(event)
    setShow(true)
  }

  const [isPlay, setIsPlay] = useState(false)
  const [teams, setteams] = useState(props.teams)

  const playVideoHandler = () => {
    setIsPlay(true)
  }

  const MotionCol = motion(Col)
  const MotionRow = motion(Row)
  const [windowWidth] = useWindowSize()

  var settings = {
    dots: false,
    nav: true,
    infinite: teams?.length > 4 ? true : false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          infinite: teams?.length > 4 ? true : false,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
          infinite: teams?.length > 4 ? true : false,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          infinite: teams?.length > 2 ? true : false,
        },
      },
      {
        breakpoint: 675,
        settings: {
          slidesToShow: 1,
          infinite: teams?.length > 1 ? true : false,
        },
      },
    ],
  }
  const ImageRender = ({ alt, image_url, node, processedImages }) => {
    return (
      <ImageTransform
        imagesources={image_url}
        renderer="srcSet"
        imagename="team.Image.tail"
        attr={{ alt: alt, class: "" }}
        imagetransformresult={processedImages}
        id={node.id}
      />
    )
  }
  return teams?.length > 0 ? (
    <div className="relatedteam-block opinion">
      <Container>
        <Row>
          <InView {...inViewOptions}>
            {({ ref, inView }) => (
              <MotionCol
                ref={ref}
                lg="12"
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                variants={contentItemStagger}
              >
                <motion.h2 variants={contentItem}>
                  {props.isPage === "team"
                    ? "Other Team Members"
                    : props.Branch_Location + " Team"}
                </motion.h2>
              </MotionCol>
            )}
          </InView>
        </Row>
        {/* {windowWidth < 1199 && */}

        <Slider className="people-List-block" {...settings}>
          {teams?.map((item, i) => {
            let processedImages = JSON.stringify({})
            if (item?.imagetransforms?.Image_Transforms) {
              processedImages = item.imagetransforms.Image_Transforms
            }
            return (
              <div className="people-block">
                <div className="person-img">
                  <Link
                    to={Team_Page_Url.alias + "/" + item.URL + "/"}
                    className="img-zoom img-wrap"
                  >
                    {/* <img src={item.Image?.url} alt={item.Image?.alternativeText} /> */}
                    {item?.Image?.url ? (
                      <ImageRender
                        alt={
                          item.Image?.alternativeText
                            ? item.Image?.alternativeText
                            : item.Name + " - DNG Estate Agents"
                        }
                        processedImages={processedImages}
                        image_url={item?.Image?.url}
                        node={item}
                      />
                    ) : (
                      <img src={NoImage} alt="no-image" />
                    )}
                    {item.Video_Url && (
                      <button
                        type="button"
                        className="play-btn"
                        onClick={playVideoHandler}
                      >
                        <i className="icon-play"></i>
                      </button>
                    )}
                  </Link>
                </div>
                <div className="person-info">
                  <span className="person-name">
                    <Link
                      to={Team_Page_Url.alias + "/" + item.URL + "/"}
                      className="img-zoom img-wrap"
                    >
                      {item.Name}
                    </Link>
                  </span>
                  <span className="position-name">{item.Designation}</span>
                  <span className="branch-name d-none d-xl-block">
                    {item.offices?.map((list, j) => (
                      <>
                        {list.Branch_Location}
                        {j === item.offices?.length - 1 ? "" : ", "}
                      </>
                    ))}
                  </span>
                  {item.Email && (
                    <a
                      href="javascript:;"
                      onClick={() => handleShow(i)}
                      className="email-text d-block d-xl-none"
                    >
                      {item.Email}
                    </a>
                  )}
                  <div className="person-contact">
                    {item.Phone ? (
                      <Link href={"tel:" + item.Phone} className="phone-number">
                        <i className="icon-phone"></i> {item.Phone}
                      </Link>
                    ) : item.Mobile_No ? (
                      <Link
                        href={"tel:" + item.Mobile_No}
                        className="phone-number"
                      >
                        <i className="icon-phone"></i> {item.Mobile_No}
                      </Link>
                    ) : (
                      ""
                    )}

                    {item.Email && (
                      <a
                        href="javascript:;"
                        onClick={() => handleShow(i)}
                        className="mail-text d-none d-xl-flex"
                      >
                        <i className="icon-mail"></i> Email
                      </a>
                    )}
                    <Link
                      to={Team_Page_Url.alias + "/" + item.URL + "/"}
                      className="arrow-symbol"
                    >
                      bio<i className="icon-arrow"></i>
                    </Link>
                    {i === index && (
                      <Modal
                        show={show}
                        centered={true}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                        dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
                      >
                        <Modal.Header closeButton className="contact-close-btn">
                          <Modal.Title className="w-100">
                            Email to {item.Name}
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="form">
                          <TeamForm
                            toemail={item.Email}
                            officeNumber={
                              item.Phone ? item.Phone : item.Mobile_No
                            }
                            teamMember={item.Name}
                          />
                        </Modal.Body>
                      </Modal>
                    )}
                  </div>
                </div>
                <PlayVideo
                  isOpen={isPlay}
                  stopPlay={setIsPlay}
                  videoId={item.Video_Url}
                  isAutoPlay={1}
                />
              </div>
            )
          })}
        </Slider>

        {/* } */}
        {/* {windowWidth > 1199 &&
					<InView {...inViewOptions}>
						{({ ref, inView }) => (
							<MotionRow
								ref={ref}
								initial="hidden"
								animate={inView ? "visible" : "hidden"}
								variants={contentItemStagger}
							>
								{props.Explore_Tails?.map((item, index) => (
									<MotionCol key={index}
										custom={index}
										variants={contentItem} md="6" xl="3" className="d-flex mb-space our-explore">
										<div className="advantage-item">
										<GetURL class="tail-link-absolute" alias={item.Explore_Cta?.Url?.Alias} />
											{item.Image?.url &&
												<picture>
													<img src={item.Image?.url} alt={item.Image?.alternativeText} />
												</picture>
											}
											<div className="advantage-info">
												<h3>{item.Title}</h3>
												{item?.Content && <ReactMarkdown source={item?.Content} allowDangerousHtml />}
												<GetURL label={item.Explore_Cta?.Label} alias={item.Explore_Cta?.Url?.Alias} />
											</div>
										</div>
									</MotionCol>
								))
								}
							</MotionRow>
						)}
					</InView>
				} */}
      </Container>
    </div>
  ) : null
}

export default RelatedTeam
